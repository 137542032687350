import Head from 'next/head';
import Link from 'next/link';
import { useWindowSize } from 'usehooks-ts';
import { Button } from '#/common/components/ui/button';
import { Separator } from '#/common/components/ui/separator';

interface Props {
  isProduct?: boolean;
}
export function NotFoundPage({ isProduct = false }: Props) {
  const { width } = useWindowSize();

  return (
    <section className="flex h-[500px] flex-col items-center justify-center gap-4 align-content-center md:flex-row">
      <Head>
        <title>404 | Entremalhas</title>
        <meta
          name="description"
          content="Página não encontrada na Entremalhas."
        />
      </Head>
      <article className="flex h-full items-center">
        <h1 className="scroll-m-20 font-extrabold text-9xl tracking-tight">404</h1>
      </article>
      <Separator
        orientation={width > 768 ? 'vertical' : 'horizontal'}
        className="w-3/4 md:h-40 md:w-[1px]"
      />
      <div className="text-end">
        <h2 className="font-extrabold lg:text-4xl dark:text-white">Ops!</h2>
        <p className="text-muted-foreground lg:text-xl">
          {isProduct ? 'Produto não encontrado' : 'Página não encontrada'}
        </p>
        <Button
          asChild
          variant="link"
          className="p-0 text-sm"
        >
          <Link href="/">Voltar para a página inicial</Link>
        </Button>
      </div>
    </section>
  );
}
